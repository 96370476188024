<template>
  <div class="sidebar" :class="{'show': showMenu}">
    <div class="sidebar_item" :class="{'active': $route.name === 'home'}" @click="goToPage('home')">
      <div class="icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5021 3.45605L12.504 3.45785L20.0041 10.2763C20.0041 10.2763 20.0042 10.2764 20.0042 10.2764C20.0816 10.3469 20.1434 10.4328 20.1857 10.5285C20.2281 10.6242 20.25 10.7278 20.2501 10.8325V19.4995C20.25 19.6361 20.213 19.7702 20.143 19.8875C20.0729 20.0048 19.9725 20.101 19.8522 20.1659L19.8515 20.1663C19.7473 20.2227 19.6304 20.2515 19.512 20.25L19.512 20.2499H19.5022H14.9968V15.7492C14.9968 15.3514 14.8388 14.9699 14.5575 14.6886C14.2762 14.4073 13.8946 14.2492 13.4968 14.2492H10.4968C10.099 14.2492 9.71745 14.4073 9.43614 14.6886C9.15484 14.9699 8.99681 15.3514 8.99681 15.7492L8.99681 20.2499L4.50029 20.2499L4.49838 20.2499C4.3926 20.2502 4.28798 20.2278 4.19155 20.1844C4.09606 20.1413 4.01078 20.0786 3.94128 20.0003C3.81584 19.854 3.74785 19.667 3.75005 19.4742L3.7501 19.4742V19.4657V10.8321C3.75023 10.7275 3.77215 10.6241 3.81447 10.5284C3.85678 10.4328 3.91857 10.347 3.99589 10.2765C3.99595 10.2765 3.99601 10.2764 3.99607 10.2764L11.495 3.45795L11.497 3.45611C11.6342 3.33046 11.8135 3.26075 11.9995 3.26074C12.1856 3.26073 12.3649 3.33041 12.5021 3.45605Z"
            stroke="#546272" stroke-width="1.5"/>
        </svg>
      </div>
      <div class="name">
        {{ $t("header.main") }}
      </div>
    </div>
    <div class="sidebar_item" :class="{'active': $route.path.includes('courses')}" @click="goToPage('courses-home')">
      <div class="icon course">
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.04596 7.69114C0.693015 7.50291 0.693015 6.99703 1.04596 6.80879L10.5294 1.75095C10.8235 1.59409 11.1765 1.59409 11.4706 1.75095L20.954 6.80879C21.307 6.99703 21.307 7.50291 20.954 7.69114L20.765 7.79195L16.1853 9.93925C16.1417 9.90055 16.0932 9.86662 16.0404 9.83847L11.3529 7.33847C10.9874 7.14355 10.5331 7.28181 10.3382 7.6473C10.1433 8.01278 10.2815 8.46708 10.647 8.662L14.5125 10.7236L10.999 12.371L1.53592 7.95246L1.04596 7.69114ZM3.8125 10.6709V13.4259C3.81224 13.5607 3.85583 13.692 3.93668 13.7999C4.46298 14.5002 6.68337 17.0623 11 17.0623C12.5903 17.0623 13.8961 16.7146 14.9375 16.2403V12.181L11.318 13.8781C11.1167 13.9725 10.8838 13.9727 10.6823 13.8786L3.8125 10.6709ZM16.4375 15.3664C17.3075 14.7344 17.8391 14.0982 18.0633 13.7999C18.1442 13.692 18.1878 13.5607 18.1875 13.4259V10.6572L16.4375 11.4777V15.3664Z"
                fill="#546272"/>
        </svg>
      </div>

      <div class="name">
        {{ $t("header.courses") }}
      </div>
    </div>
    <!-- <div class="sidebar_item">
        <div class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8.25C12 7.45435 12.3161 6.69129 12.8787 6.12868C13.4413 5.56607 14.2044 5.25 15 5.25H21C21.1989 5.25 21.3897 5.32902 21.5303 5.46967C21.671 5.61032 21.75 5.80109 21.75 6V18C21.75 18.1989 21.671 18.3897 21.5303 18.5303C21.3897 18.671 21.1989 18.75 21 18.75H15C14.2044 18.75 13.4413 19.0661 12.8787 19.6287C12.3161 20.1913 12 20.9544 12 21.75" stroke="#546272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.25 18C2.25 18.1989 2.32902 18.3897 2.46967 18.5303C2.61032 18.671 2.80109 18.75 3 18.75H9C9.79565 18.75 10.5587 19.0661 11.1213 19.6287C11.6839 20.1913 12 20.9544 12 21.75V8.25C12 7.45435 11.6839 6.69129 11.1213 6.12868C10.5587 5.56607 9.79565 5.25 9 5.25H3C2.80109 5.25 2.61032 5.32902 2.46967 5.46967C2.32902 5.61032 2.25 5.80109 2.25 6V18Z" stroke="#546272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="name">
            {{ $t("header.knowledge_lib") }}
        </div>
    </div>
    <div class="sidebar_item">
        <div class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.375 20.249H4.46875C4.27813 20.249 4.09531 20.1733 3.96052 20.0385C3.82573 19.9037 3.75 19.7209 3.75 19.5303V11.624C3.75 10.4914 3.97309 9.3698 4.40654 8.32337C4.83998 7.27694 5.4753 6.32613 6.2762 5.52522C7.0771 4.72432 8.02791 4.08901 9.07435 3.65556C10.1208 3.22211 11.2423 2.99902 12.375 2.99902H12.375C13.5077 2.99902 14.6292 3.22212 15.6756 3.65556C16.7221 4.08901 17.6729 4.72432 18.4738 5.52523C19.2747 6.32613 19.91 7.27695 20.3435 8.32338C20.7769 9.36981 21 10.4914 21 11.624V11.624C21 13.9115 20.0913 16.1053 18.4738 17.7228C16.8563 19.3403 14.6625 20.249 12.375 20.249Z" stroke="#546272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.4609 12C13.4609 12.5997 12.9747 13.0859 12.375 13.0859C11.7753 13.0859 11.2891 12.5997 11.2891 12C11.2891 11.4003 11.7753 10.9141 12.375 10.9141C12.9747 10.9141 13.4609 11.4003 13.4609 12Z" fill="#546272" stroke="#546272" stroke-width="0.078125"/>
            <path d="M8.96094 12C8.96094 12.5997 8.47475 13.0859 7.875 13.0859C7.27525 13.0859 6.78906 12.5997 6.78906 12C6.78906 11.4003 7.27525 10.9141 7.875 10.9141C8.47475 10.9141 8.96094 11.4003 8.96094 12Z" fill="#546272" stroke="#546272" stroke-width="0.078125"/>
            <path d="M16.875 13.125C17.4963 13.125 18 12.6213 18 12C18 11.3787 17.4963 10.875 16.875 10.875C16.2537 10.875 15.75 11.3787 15.75 12C15.75 12.6213 16.2537 13.125 16.875 13.125Z" fill="#546272"/>
            </svg>
        </div>
        <div class="name">
            {{ $t("header.messages") }}
        </div>
        <span class="notification">
            3
        </span>
    </div>
    <div class="sidebar_item">
        <div class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.75 20.25L8.25 20.25C7.05653 20.25 5.91193 19.7759 5.06802 18.932C4.22411 18.0881 3.75 16.9435 3.75 15.75L3.75 8.25C3.75 7.05653 4.22411 5.91193 5.06802 5.06802C5.91193 4.22411 7.05653 3.75 8.25 3.75L15.75 3.75C16.9435 3.75 18.0881 4.22411 18.932 5.06802C19.7759 5.91193 20.25 7.05653 20.25 8.25L20.25 12.75C19.5 15 15 19.5 12.75 20.25Z" stroke="#546272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.75 20.25V17.25C12.75 16.0565 13.2241 14.9119 14.068 14.068C14.9119 13.2241 16.0565 12.75 17.25 12.75H20.25" stroke="#546272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="name">
            {{ $t("header.blog") }}
        </div>
    </div>
    <div class="sidebar_item">
        <div class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.90334 8.58739C5.07209 8.23114 5.26897 7.89364 5.49397 7.56551L5.45647 5.12801C5.45647 4.90301 5.55022 4.68739 5.72834 4.53739C6.60022 3.80614 7.59397 3.21551 8.69084 2.82176C8.90647 2.74676 9.14084 2.76551 9.32834 2.88739L11.419 4.14364C11.8127 4.11551 12.2065 4.11551 12.6002 4.14364L14.6908 2.88739C14.8877 2.77489 15.1221 2.74676 15.3377 2.82176C16.4065 3.20614 17.4096 3.77801 18.3002 4.52801C18.469 4.66864 18.5721 4.89364 18.5627 5.11864L18.5252 7.55614C18.7502 7.88426 18.9471 8.22176 19.1158 8.57801L21.244 9.75926C21.4408 9.87176 21.5815 10.0593 21.619 10.2843C21.8158 11.3999 21.8252 12.5624 21.619 13.6968C21.5815 13.9218 21.4408 14.1093 21.244 14.2218L19.1158 15.403C18.9471 15.7593 18.7502 16.0968 18.5252 16.4249L18.5627 18.8624C18.5627 19.0874 18.469 19.303 18.2908 19.453C17.419 20.1843 16.4252 20.7749 15.3283 21.1686C15.1127 21.2436 14.8783 21.2249 14.6908 21.103L12.6002 19.8468C12.2065 19.8749 11.8127 19.8749 11.419 19.8468L9.32834 21.103C9.13147 21.2155 8.89709 21.2436 8.68147 21.1686C7.61272 20.7843 6.60959 20.2124 5.71897 19.4624C5.55022 19.3218 5.44709 19.0968 5.45647 18.8718L5.49397 16.4343C5.26897 16.1061 5.07209 15.7686 4.90334 15.4124L2.77522 14.2311C2.57834 14.1186 2.43772 13.9311 2.40022 13.7061C2.20334 12.5905 2.19397 11.428 2.40022 10.2936C2.43772 10.0686 2.57834 9.88114 2.77522 9.76864L4.90334 8.58739Z" stroke="#546272" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z" stroke="#546272" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="name">
            {{ $t("header.settings") }}
        </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BaseSideBar',
  props: ['showMenu'],

  methods: {
    goToPage(pageName) {
      this.$emit('toggleSideMenu', false)
      this.$router.push({name: pageName})
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 300px;
  min-width: 300px;
  background: #fff;
  padding-top: 30px;
  left: 0;
  margin-left: 0;
  opacity: 1;
  top: 0;
  min-height: 100vh;
  transition: 0.5s ease;
  z-index: 20;

  &_item {
    display: flex;
    align-items: center;
    position: relative;
    height: 45px;
    padding-left: 15px;
    gap: 15px;
    cursor: pointer;

    .name {
      color: #546272;
      font-weight: 500;
    }

    .notification {
      background: #ED0000;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      color: #fff;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      text-align: center;
    }

    &.active, &:hover {
      background: #F9FBFF;

      .name {
        color: #007BFC;
      }

      .icon.course path {
        fill: #007BFC;
        stroke: transparent;
      }

      .icon path {
        stroke: #007BFC;
      }

      &:before {
        background: #007BFC;
      }
    }

    &:before {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: transparent;
      border-radius: 0px 3px 3px 0px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .sidebar {
    left: -100%;
    margin-left: -300px;
    opacity: 0;

    &.show {
      margin-left: 0;
      left: 0;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 720px) {
  .sidebar {
    position: fixed;
    top: 0;
    padding-top: 80px;
    height: 100%;
  }
}
</style>

<template>
  <div class="courses">
    <TheHeader :sideMenu="sideMenu" @toggleSideMenu="toggleMenu"/>
    <div class="courses_body" @click="sideMenu = false">
      <BaseSidebar :showMenu="sideMenu" @toggleSideMenu="toggleMenu"/>
      <div class="courses_content" :class="{'full': sideMenu}">
        <router-view />
      </div>
    </div>
    <Footer class="footer"/>
  </div>
</template>

<script>
import BaseSidebar from '../../../components/BaseSidebar.vue'
import Footer from '../../../components/FooterNew.vue'
import TheHeader from '../../../components/TheHeader.vue'

export default {
  components: {BaseSidebar, Footer, TheHeader},

  data() {
    return {
      sideMenu: true
    }
  },

  methods: {
    toggleMenu(value) {
      this.sideMenu = value
    }
  }
}
</script>

<style lang="scss" scoped>

.courses {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &_body {
    background: #F6F7F8;
    display: flex;
    min-height: calc(100vh - 148px);
  }

  &_content {
    width: calc(100% - 300px);
    padding: 32px 64px 60px 60px;
  }

  .footer {
    margin-top: auto;
  }
}

@media screen and (max-width: 1600px) {
  .courses {
    &_content {
      margin-left: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .courses {
    &_content {
      // margin-left: 300px;
      padding: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .courses {
    &_content {
      padding: 20px;
    }
  }
}

</style>

<template>
  <div>
    <Header class="header" tag="header">
      <div class="header__inner">
        <div
          class="header_burger"
          :class="{'active': sideMenu}"
          @click="toggleSideMenu(!sideMenu)"
        >
          <div class="header_burger_item"></div>
          <div class="header_burger_item"></div>
          <div class="header_burger_item"></div>
        </div>
        <router-link to="/" class="header__logo">
          <img
            src="@/assets/images/djoon_logo.svg"
            width="122"
            height="34"
            alt="Logo"
          />
        </router-link>

        <div class="dropdown_menu">
          <div class="dropbtn_menu" @click-outside="outsideClick" @click="insideClick">{{ locale }}</div>
          <div class="dropdown_content" v-if="contentVisible">
            <a @click="switchLocale('ru')" href="#">Русский</a>
            <a @click="switchLocale('kz')" href="#">Қазақша</a>
          </div>
        </div>

        <div class="header__auth" v-if="!logged">
          <router-link to="/signup" class="header__btn-sign-up" type="button">Регистрация</router-link>
          <router-link to="/signin" class="header__btn-sign-in" type="button">Войти</router-link>
        </div>
        <div class="navbar_profile_dropdown_menu" v-else>
          <button class="sign_in_profile" @click-outside="outside" @click="inside">
            <div :style="getImage()" class="profile_avatar"></div>
            <div class="profile_info">
              <div class="profile_name">{{ user.email }}</div>
              <div class="profile_role">{{ user.iin }}</div>
            </div>
          </button>
          <div v-show="showProfileMenu" class="navbar_profile_dropdown_content">
            <div class="navbar_profile_dropdown_back">
              <router-link class="navbar__item profile" :to="{ name: 'profile' }">{{ $t('Profile') }}</router-link>
              <router-link class="navbar__item courses" :to="{ name: 'courses-home' }">{{
                  $t('My_courses')
                }}
              </router-link>
              <router-link class="navbar__item interviews" :to="{ name: 'interviews' }">{{ $t('Interviews') }}
              </router-link>
              <div class="navbar_profile_dropdown_border"/>
              <div class="navbar__item logout" @click="logoutMethod">{{ $t('logout') }}</div>
            </div>
          </div>
        </div>
      </div>
    </Header>
    <!-- <BaseSidebar :showMenu="sideMenu" @toggleSideMenu="toggleSideMenu"/> -->
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import BaseSidebar from './BaseSidebar.vue';

export default {
  components: {BaseSidebar},
  name: "TheHeader",
  props: ['sideMenu'],

  data() {
    return {
      locale: localStorage.getItem('locale'),
      contentVisible: false,
      showProfileMenu: false
    };
  },
  methods: {
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      //this.$router.go(this.$router.currentRoute)
      location.reload();
    },
    outsideClick: function () {
      this.contentVisible = false
    },
    insideClick: function () {
      this.contentVisible = !this.contentVisible
    },
    outside: function () {
      this.showProfileMenu = false
    },
    inside: function () {
      this.showProfileMenu = !this.showProfileMenu
    },
    getImage() {
      // const path = 'https://api.djoon.kz/images' + this.user.avatar
      const path = this.user.avatar || 'avatars/Avatar-1.svg'
      return `background-image: url('${path}')`;
    },
    logoutMethod() {
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      localStorage.removeItem('basket_id');
      localStorage.removeItem('coins');
      window.location = '/';
    },
    toggleSideMenu(sideMenu) {
      this.$emit('toggleSideMenu', sideMenu)
    }
  },


  computed: {
    ...mapGetters(["getUser"]),
    user: function () {
      return JSON.parse(this.getUser)
    },
    logged: function () {
      let user = localStorage.getItem('user')
      let token = localStorage.getItem('token')
      return token !== null && token !== '' && user !== null && user !== ''
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 16px 44px;
  background: rgba(255, 255, 255, 0.43);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 22;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    margin-right: auto;
  }

  &__auth {
    font-family: "Roboto", sans-serif;
    font-size: 14px;

    > * {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &__btn-sign-up {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #0b1f35;
    border: none;
    padding: 6px 29px;
    border-radius: 6px;
    background: none;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__btn-sign-in {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #0b1f35;
    border: 1px solid #0b1f35;
    padding: 6px 29px;
    border-radius: 6px;
    background: none;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__menu {
  }

  &__menu-link {
    color: #0b1f35;
    transition: color 0.2s ease-out;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 36px;
    }

    &:hover {
      color: #007bfc;
    }

    .router-link-exact-active {
      color: #007bfc;
    }
  }

  &_burger {
    width: 28px;
    height: 28px;
    padding: 8px 5px;
    display: none;
    justify-content: space-between;
    flex-direction: column;
    gap: 3px;
    margin-right: 20px;
    position: relative;
    cursor: pointer;

    &.active {
      .header_burger_item {
        position: absolute;
        top: 50%;
        width: 18px;

        &:first-child {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }

    &_item {
      background: #0B1F35;
      height: 2px;
      width: 100%;
      border-radius: 2px;
      transition: 0.3s ease;
    }
  }
}

.profile_avatar {
  background-color: #F6F7F8;
}

.sign_in_profile::after {
  display: none;
}

.header__menu-link.router-link-exact-active {
  color: #007bfc;
}

@media screen and (max-width: 1600px) {
  .header {
    &_burger {
      display: flex;
    }
  }
}

@media screen and (max-width: 720px) {
  .navbar_profile_dropdown_content .navbar_profile_dropdown_back {
    position: absolute;
    right: 0;
    width: 200px;
  }
  .header {
    padding: 10px 16px;
    position: fixed;
    width: 100%;
  }
  .profile {
    &_info {
      display: none;
    }
  }
}

</style>
